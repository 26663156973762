import './distributiva_restaurantes_pa.scss';
import React from 'react';
import TrackVisibility from 'react-on-screen';
import { graphql } from 'gatsby';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Asgard } from '../src/components/Asgard/Asgard';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Buttons from '../src/components/Buttons/Buttons';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';
import tt from '../src/helpers/translation';
import InfoCtaPanel from '../src/components/InfoCtaPanel/InfoCtaPanel';

class Restaurantes extends React.Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    filterData: {},
    initialFilterStateMundo: true,
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('MUNDO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, world: name };
      this.setState({ world: name, initialFilterStateMundo: false, filterData: newFilterData });
    } else if (tag === tt('TIPO DE MENÚ', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    } else if (tag === tt('TIPO DE SERVICIO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoMenu: name };
      this.setState({
        tipoMenu: name,
        initialFilterStateServicios: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateMenu: true,
      initialFilterStateServicios: true,
      filterData: newFilterData,
    });
  };

  render() {
    const restaurantsData = {
      text: this.props.data.restaurantHeaderBlock,
      image: {
        url: this.props.data.restaurantHeaderImageBlock.localImage.childImageSharp.fluid,
      },
      cards: this.props.data.allRestaurantsSubBlock.edges,
      breadcrumb: this.props.data.restaurantsBreadCrumbBlock,
      promotions: this.props.data.allRestaurantsPromotedSubBlock.edges,
    };

    const buttons = {
      cta: tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const heimdallData = {
      name: restaurantsData.text.title.toUpperCase(),
      subtitle: restaurantsData.text.subtitle,
      image: restaurantsData.image,
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      text: `${restaurantsData.cards.length} ${tt('restaurantes', this.props.pageContext.locale)}`,
      filters: [
        {
          tag: tt('MUNDO', this.props.pageContext.locale),
          options: [
            { value: tt('Mediterrània', this.props.pageContext.locale), label: 'Mediterrània' },
            { value: tt('China', this.props.pageContext.locale), label: 'China' },
            { value: tt('Polynesia', this.props.pageContext.locale), label: 'Polynesia' },
            { value: tt('México', this.props.pageContext.locale), label: 'México' },
            {
              value: tt('SésamoAventura', this.props.pageContext.locale),
              label: 'Sesamo Aventura',
            },
            { value: tt('Far West', this.props.pageContext.locale), label: 'Far West' },
          ],
        },
        {
          tag: tt('TIPO DE MENÚ', this.props.pageContext.locale),
          options: [
            {
              value: tt('Comida para celiacos', this.props.pageContext.locale),
              label: 'celiac_food',
            },
            { value: tt('Menú infantil', this.props.pageContext.locale), label: 'children_menu' },
            { value: tt('Menú express', this.props.pageContext.locale), label: 'express_menu' },
          ],
        },
        {
          tag: tt('TIPO DE SERVICIO', this.props.pageContext.locale),
          options: [
            { value: tt('Autoservicio', this.props.pageContext.locale), label: 'self_serive' },
            { value: tt('Servicio a mesa', this.props.pageContext.locale), label: 'table_service' },
            { value: tt('On the go', this.props.pageContext.locale), label: 'on_the_go' },
          ],
        },
      ],
    };
    const freyaDataRides = {
      cards: restaurantsData.cards
        .map((restaurant) => {
          if (restaurant.node.buttons.cta2 === '') {
            return {
              image: restaurant.node.localImage.childImageSharp.fluid,
              altImage: restaurant.node.image_alt,
              imgsize: restaurant.node.imgsize,
              title: restaurant.node.title,
              description: restaurant.node.description,
              disabled: restaurant.node.disabled,
              seeMore: restaurant.node.seeMore,
              located:
                restaurant.node.located === 'Sesamo Aventura'
                  ? 'SésamoAventura'
                  : restaurant.node.located.charAt(0).toUpperCase() +
                    restaurant.node.located.slice(1),
              features: restaurant.node.features.split(' '),
              possibleFilters: [
                ...restaurant.node.possibleFilters1.split(' '),
                restaurant.node.possibleFilters2.toLowerCase(),
              ],
              buttons: {
                cta: restaurant.node.buttons.cta,
                size: restaurant.node.buttons.size,
                color: restaurant.node.buttons.color,
                ctaText: restaurant.node.buttons.ctaText,
              },
            };
          }
          return {
            image: restaurant.node.localImage.childImageSharp.fluid,
            altImage: restaurant.node.image_alt,
            imgsize: restaurant.node.imgsize,
            title: restaurant.node.title,
            description: restaurant.node.description,
            disabled: restaurant.node.disabled,
            seeMore: restaurant.node.seeMore,
            located: restaurant.node.located,
            features: restaurant.node.features,
            possibleFilters: restaurant.node.possibleFilters1 + restaurant.node.possibleFilters2,
            twoButton: true,
            buttons: {
              cta: restaurant.node.buttons.cta,
              size: restaurant.node.buttons.size,
              color: restaurant.node.buttons.color,
              ctaText: restaurant.node.buttons.ctaText,
              cta2: restaurant.node.buttons.cta2,
              size2: restaurant.node.buttons.size2,
              color2: restaurant.node.buttons.color2,
              ctaText2: restaurant.node.buttons.ctaText2,
              blank2: true,
            },
          };
        })
        .filter((item) => {
          // return item;
          const setFilters = [];
          for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
          if (!setFilters.length) return item;
          if (setFilters.every((elem) => item.possibleFilters.indexOf(elem) > -1)) return item;
        }),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: restaurantsData.promotions.map((restaurant) => ({
        image: restaurant.node.localImage.childImageSharp.fluid,
        altImage: restaurant.node.image_alt,
        title: restaurant.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: restaurant.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: restaurant.node.ctaText,
        },
      })),
    };
    const infoButtonsData = [
      {
        cta: tt('/restaurantes/los-mejores-sitios-para-comer', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('Leer más', this.props.pageContext.locale),
        description: tt(
          '<p><b>LOS MEJORES SITIOS PARA COMER EN PORTAVENTURA WORLD</b></p>',
          this.props.pageContext.locale
        ),
        border: false,
        blank: false,
      },
    ];

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allRestaurantsSeoBlock.edges[0].node._0.title}
          description={this.props.data.allRestaurantsSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allRestaurantesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.restaurantHeaderImageBlock.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="Asgard-box">
            <TrackVisibility {...this.state}>
              <Asgard
                locale={this.props.pageContext.locale}
                filterStatusMundo={this.state.initialFilterStateMundo}
                filterStatusTipoMenu={this.state.initialFilterStateMenu}
                filterStatusTipoServicios={this.state.initialFilterStateServicios}
                resetFilter={this.resetFilter}
                filterChange={this.handleFilter}
                data={AsgardData}
              />
            </TrackVisibility>
          </div>
          <Buttons
            link={buttons.cta}
            size={buttons.size}
            color={buttons.color}
            text={buttons.ctaText}
          />
          <BlueArrow />
          <div className="general-index">
            <div className="restaurants-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allRestaurantsBreadCrumbBlock.edges[0].node.name}
              />
              <InfoCtaPanel buttons={infoButtonsData} />

              <AnounceCovid
                h2
                text={tt(
                  'EL FUNCIONAMIENTO HABITUAL DE CIERTOS RESTAURANTES PUEDE VERSE ALTERADO O LIMITADO TEMPORALMENTE EN ATENCIÓN A LAS RESTRICCIONES DERIVADAS DE LAS MEDIDAS PREVENTIVAS COVID-19 IMPLEMENTADAS.',
                  this.props.pageContext.locale
                )}
              />

              <Freya data={freyaDataRides} />
              <div className="restaurants-content-promo">
                <H4 title={freyaData.title} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Restaurantes;

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query restaurantesQuery($locale: String!) {
    restaurantHeaderBlock(park: { eq: "PortAventura" }, lang: { eq: $locale }) {
      title
      subtitle
    }
    allRestaurantsSeoBlock(filter: { _2: { park: { eq: "PortAventura" } } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    restaurantHeaderImageBlock(park: { eq: "PortAventura" }, lang: { eq: $locale }) {
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allRestaurantsSubBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          disabled
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
            cta2
            size2
            color2
            ctaText2
          }
        }
      }
    }
    allRestaurantsPromotedSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allRestaurantsBreadCrumbBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allRestaurantesRichData(filter: { extra: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;
